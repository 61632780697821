import React from 'react';

import Layout from 'components/Layout';

import style from './404.module.css';

const PageNotFoundPage = () => (
  <Layout title="Page Not Found">
    <h1 className={ style.header } full-width="true">Page Not Found</h1>

    <p>
      The page you're looking for does not exist. Considering my history with
      blogging, this is actually pretty likely; it seems like I relaunch this
      blog more than I post on it. You may want to <a href="/">start at the
      top</a> to see if I've posted anything new (or anything at all for that
      matter).
    </p>
  </Layout>
);

export default PageNotFoundPage;
